import React from "react";
import "../../css/Children_Art_Course.css";
import Nav from "../../componet/Nav";
import Footer from "../../componet/Footer";

const Still_life_drawing = () => {
  return (
    <>
      <Nav />
      <div className="children-art-course-container">
        <div className="children-art-course">
          <div className="course_Img">
            <h1>*Still Life Drawing & Painting ~</h1>
          </div>
          <div className="content">
            <p>
              Still life drawing and painting involves creating artwork based on
              inanimate objects arranged in a composition. This art form focuses
              on capturing the details, textures, and lighting of everyday items
              like fruit, flowers, vases, bottles, and other objects.
            </p>
            <h1>Key aspects of still life drawing and painting include:</h1>
            <ul>
              <li>
                <strong>1. Observation Skills: </strong>Artists develop the
                ability to closely observe objects, noting fine details,
                textures, and proportions. This enhances drawing and painting
                accuracy.
              </li>
              <li>
                <strong>2. Shading and Light:</strong> Learning how to depict
                light and shadow helps create depth and realism in the artwork.
              </li>

              <li>
                <strong>3. Composition:</strong>Arranging the objects in a
                visually interesting way teaches students about balance, space,
                and visual flow within the artwork.
              </li>

              <li>
                <strong>4. Techniques:</strong> Artists use various media like
                pencil, charcoal, watercolor, or oils, each bringing out
                different effects and textures.
              </li>

              <li>
                <strong>5. Focus on Detail:</strong>Still life allows artists to
                practice focusing on minute details, like reflections, light
                play, and texture, making it a valuable exercise for both
                beginners and advanced artists.
              </li>
              <li>
                Still life drawing and painting help artists refine their
                technical skills and attention to detail, offering a structured
                and controlled way to practice realism and composition.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Still_life_drawing;
