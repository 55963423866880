import React from "react";
import "../../css/Children_Art_Course.css";
import Nav from "../../componet/Nav";
import Footer from "../../componet/Footer";


const Children_Art_course = () => {
  return (
    <>
      <Nav />
      <div className="children-art-course-container">
        <div className="children-art-course">
          <div className="course_Img">
            <h1>*Children Art Course ~</h1>
          </div>
          <div className="content">
            <p>
              A Children's Art Course is a creative program designed to teach
              young learners the fundamentals of visual arts in a fun and
              engaging way. It introduces children to various artistic
              techniques and media, such as drawing, painting, sculpture,
              collage, and digital art. The course typically focuses on
              developing children's imagination, fine motor skills, and
              self-expression, while encouraging creativity and exploration.
            </p>
            <h1>Key features of a children's art course include:</h1>
            <ul>
              <li>
                <strong>Age-appropriate activities:</strong> Tailored to suit different age
                groups, from toddlers to pre-teens.
              </li>
              <li>
               <strong> Skill development:</strong> Fosters basic art skills, such as coloring,
                shapes, composition, and texture.
              </li>
              <li>
                <strong>Creative expression:</strong> Encourages children to express their
                thoughts, emotions, and ideas through art.
              </li>
              <li>
                <strong>Fun and interactive:</strong> Incorporates games, stories, and projects
                to make learning enjoyable.
              </li>
              <li>
                <strong>Art appreciation:</strong> Introduces children to famous artists and art
                history in an easy-to-understand manner.
              </li>
              <li>
               <strong>Boosts confidence:</strong> Helps children gain confidence in their
                artistic abilities while promoting problem-solving and critical
                thinking.
              </li>
              <li>
                These courses can be offered in schools, art centers, or as
                extracurricular activities, and are designed to inspire a
                lifelong interest in the arts.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Children_Art_course;
