import React from "react";
import "../../css/Children_Art_Course.css";
import Nav from "../../componet/Nav";
import Footer from "../../componet/Footer";

const Diploma_In_Fine = () => {
  return (
    <>
      <Nav />
      <div className="children-art-course-container">
        <div className="children-art-course">
          <div className="course_Img">
            <h1>*Diploma in Fine Arts ~</h1>
          </div>
          <div className="content">
            <p>
              A Diploma in Fine Arts is a specialized academic program that
              focuses on developing students' skills and knowledge in various
              forms of visual art, including painting, drawing, sculpture,
              printmaking, and digital arts. The course typically combines
              practical training with theoretical understanding, helping
              students learn techniques, explore artistic concepts, and develop
              their creative abilities. This diploma is designed for individuals
              interested in pursuing careers as artists, art teachers, or
              art-related professionals, offering a solid foundation for further
              studies or entry into the creative industry. The duration and
              curriculum of the program can vary, but it generally aims to
              foster artistic talent and enhance the appreciation of fine arts.
            </p>
            <h1>Here are the key points about a Diploma in Fine Arts:</h1>
            <ul>
              <li>
                <strong>1.Program Focus:</strong> The course focuses on
                enhancing creative skills in various art forms, such as
                painting, drawing, sculpture, printmaking, and digital arts.
              </li>
              <li>
                <strong>2.Duration:</strong> Typically lasts between 1 to 3
                years, depending on the institution and country.
              </li>
              <li>
                <strong>3.Curriculum:</strong> Includes both practical and
                theoretical components, covering topics like art techniques,
                design principles, color theory, art history, and creative
                expression.
              </li>
              <li>
                <strong>4.Skill Development:</strong> Aims to build technical
                proficiency in different mediums, alongside fostering individual
                artistic style and creativity.
              </li>
              <li>
                <strong>5.Career Opportunities:</strong> Graduates can pursue
                careers as professional artists, illustrators, art teachers,
                graphic designers, or curators, or continue with higher
                education in specialized art fields.
              </li>
              <li>
                <strong>6.Eligibility:</strong> Generally requires a high school
                diploma or equivalent for admission.
              </li>
              <li>
                <strong>7.Objective:</strong> To provide a comprehensive
                understanding of fine arts and prepare students for further
                studies or professional work in the creative industry.
              </li>
              <li>
                <strong>8.Hands-on Experience:</strong> Emphasizes practical work
                and real-world application, often involving workshops, studio
                time, and exhibitions.
              </li>
              <li>
                <strong>9.Artistic Exploration:</strong> Encourages
                experimentation with various media and styles, fostering a deep
                personal connection to art.
              </li>
              <li>
                <strong>10.Art History:</strong> Includes exposure to the history
                of art, helping students understand cultural and historical
                contexts of various artistic movements.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Diploma_In_Fine;
