import React from 'react';
import ReactDOM from 'react-dom';
import './css/reset.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import pages
import Home from './page/Home';
import Error from './page/Error';
import Contact from './page/Contact';
import Workshop from './page/Workshop';
import Course from './page/Course';
import Gallery from './page/Gallery';
import Children_Art_course from './page/course_Info/Children_Art_course';
import Diploma_In_Fine from './page/course_Info/Diploma_In_Fine';
import Tanjore_Painting from './page/course_Info/Tanjore_Painting';
import Lower_higher_grade from './page/course_Info/Lower_higher_grade';
import Still_life_drawing from './page/course_Info/Still_life_drawing';
import Watercolor_painting from './page/course_Info/Watercolor_painting';
import Kids_Drawing_painting_craft from './page/course_Info/Kids_Drawing_painting_craft';
import AdmissionForm from './page/AdmissionForm';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/course" element={<Course />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="*" element={<Error />} />

          {/* Couser Info Pages */}

          <Route path="/course/children-art-course" element={<Children_Art_course />} />
          <Route path="/course/diploma-in-fine-arts" element={<Diploma_In_Fine />} />
          <Route path="/course/diploma-tanjore-painting" element={<Tanjore_Painting />} />
          <Route path="/course/free-hand-drawing-lower-higher-grade" element={<Lower_higher_grade />} />
          <Route path="/course/still-life-drawing&painting" element={<Still_life_drawing />} />
          <Route path="/course/water-painting-course" element={<Watercolor_painting />} />
          <Route path="/kids-drawing&painting&arts&craft" element={<Kids_Drawing_painting_craft />} />
          <Route path="/admission-from" element={<AdmissionForm />} />

      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// Performance measuring (optional)
reportWebVitals();
