import React from "react";
import "../../css/Children_Art_Course.css";
import Nav from "../../componet/Nav";
import Footer from "../../componet/Footer";

const Lower_higher_grade = () => {
  return (
    <>
      <Nav />
      <div className="children-art-course-container">
        <div className="children-art-course">
          <div className="course_Img">
            <h1>*Free hand drawing lower & higher grade ~</h1>
          </div>
          <div className="content">
            <h3>drawing for lower grade :</h3>
            <p>
            Freehand drawing for lower grades refers to the practice of drawing without the use of rulers, stencils, or other tools for precise measurements. It encourages young students to express themselves creatively, focusing on the natural flow of their hand and imagination. In lower grades, freehand drawing helps children develop basic drawing skills, hand-eye coordination, and an understanding of shapes, lines, and patterns. The emphasis is on self expression, exploration, creativity, and the enjoyment of the process rather than achieving perfect accuracy. This approach builds confidence in young learners and helps foster their artistic expression. And foster a sense of imagination, making it a fun and educational activity for young learners.
            </p>
            <h3>drawing for higher grade :</h3>
            <p>
            Freehand drawing at a higher level refers to the more advanced practice of drawing without tools, focusing on precision, technique, and detailed representation. At this level, artists or students refine their ability to depict complex subjects with accuracy, using their knowledge of perspective, shading, and proportions. It requires a deeper understanding of form, anatomy, and composition, often applied to intricate subjects such as landscapes, portraits, or still life. The focus shifts from basic shapes to capturing depth, texture, and realism, while maintaining the freedom and fluidity of the hand-drawn process.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Lower_higher_grade;
