
import React from 'react';
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import '../css/Workshop.css'; // Import your CSS file for styling
import Gallery_view from '../componet/Gallery_view';
// import { Helmet } from 'react-helmet';


const Gallery = () => {

    

  return (
    <>

    <Nav/>
            {/* -------------------------------- */}
            <div className='Workshop-container'>
                <div className='workshop'>
                    <div className='heading'>
                        <h1>Gallery</h1>
                    </div>
                </div>
            </div>
            <Gallery_view/>
            {/* -------------------------------- */}
            <Footer />
    </>
  )
}
export default Gallery

