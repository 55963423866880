import React from "react";
import "../../css/Children_Art_Course.css";
import Nav from "../../componet/Nav";
import Footer from "../../componet/Footer";

const Watercolor_painting = () => {
  return (
    <>
      <Nav />
      <div className="children-art-course-container">
        <div className="children-art-course">
          <div className="course_Img">
            <h1>*Watercolor Painting Course ~</h1>
          </div>
          <div className="content">
            <p>
              Watercolor painting is a popular artistic technique that uses
              water-based paints to create vibrant, transparent artworks. The
              paint is made from pigments mixed with water, allowing for smooth
              blending and fluidity. This medium is known for its ability to
              produce soft washes, delicate hues, and beautiful, layered
              effects.
            </p>
            <h1>Key features of watercolor painting include:</h1>
            <ul>
              <li>
                <strong>1. Transparency: </strong>Watercolors allow light to
                pass through the layers of paint, creating a luminous,
                transparent effect. This is ideal for capturing subtle nuances
                in color and light.
              </li>
              <li>
                <strong>2. Fluidity:</strong> The paint is applied with water,
                allowing for blending, gradients, and flowing textures, making
                the process feel spontaneous and dynamic.
              </li>

              <li>
                <strong>3. Techniques: </strong>Common techniques include
                wet-on-wet (painting on a wet surface for smooth, blending
                effects) and wet-on-dry (painting on a dry surface for sharper
                lines and details). Artists also use dry brushing, lifting, and
                masking to create various effects.
              </li>

              <li>
                <strong>4. Layering: </strong> Watercolor often involves
                building layers of transparent washes to create depth and
                dimension. Each layer dries quickly, allowing artists to work
                with a mix of subtle transitions and bold contrasts.
              </li>

              <li>
                <strong>5. Color Mixing:</strong> Watercolors encourage
                experimentation with color mixing and blending. The ability to
                dilute or intensify the paint with water gives artists control
                over tone and opacity.
              </li>

              <li>
                <strong>6. Textural Effects:</strong> The medium can create
                beautiful textures through techniques like splattering, salt, or
                the use of specialized brushes.
              </li>
              <li>
                Watercolor painting is widely admired for its ethereal, delicate
                quality and is used for landscapes, portraits, abstract art, and
                still life. It offers a flexible and expressive way to capture
                light, mood, and movement.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Watercolor_painting;
