import React, { useState } from "react";
import axios from "axios";
import Nav from "../componet/Nav";
import Footer from "../componet/Footer";
import "../css/AdmissionForm.css";

const AdmissionForm = () => {
  const [formData, setFormData] = useState({
    studentName: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: "",
    gender: "",
    standard: "",
    phoneNo: "",
    address: "",
    schoolName: "",
    email: "",
    infoSource: "",
    classPlace: "",
    studentPhoto: null,
  });

  const [errors, setErrors] = useState({
    studentName: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: "",
    gender: "",
    standard: "",
    phoneNo: "",
    address: "",
    schoolName: "",
    email: "",
    infoSource: "",
    classPlace: "",
    studentPhoto: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Reset the error when the user starts typing
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if file is an image
    if (file && file.type.startsWith("image")) {
      // Check file size (3MB limit)
      if (file.size > 3 * 1024 * 1024) {
        setErrors({
          ...errors,
          studentPhoto: "Image size should be less than 3MB.",
        });
      } else {
        setFormData({
          ...formData,
          studentPhoto: file,
        });
        setErrors({
          ...errors,
          studentPhoto: "", // Clear error if valid
        });
      }
    } else {
      setErrors({
        ...errors,
        studentPhoto: "Please upload a valid image.",
      });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Add validation checks for each field
    if (!formData.studentName) {
      formErrors.studentName = "Student name is required.";
      isValid = false;
    }

    if (!formData.fatherName) {
      formErrors.fatherName = "Father's name is required.";
      isValid = false;
    }

    if (!formData.motherName) {
      formErrors.motherName = "Mother's name is required.";
      isValid = false;
    }

    if (!formData.dateOfBirth) {
      formErrors.dateOfBirth = "Date of birth is required.";
      isValid = false;
    }

    if (!formData.gender) {
      formErrors.gender = "Gender is required.";
      isValid = false;
    }

    if (!formData.standard) {
      formErrors.standard = "Standard is required.";
      isValid = false;
    }

    if (!formData.phoneNo) {
      formErrors.phoneNo = "Phone number is required.";
      isValid = false;
    }

    if (!formData.address) {
      formErrors.address = "Address is required.";
      isValid = false;
    }

    if (!formData.schoolName) {
      formErrors.schoolName = "School name is required.";
      isValid = false;
    }

    if (!formData.email) {
      formErrors.email = "Email is required.";
      isValid = false;
    }

    if (!formData.infoSource) {
      formErrors.infoSource = "How did you hear about us? is required.";
      isValid = false;
    }

    if (!formData.classPlace) {
      formErrors.classPlace = "Preferred class location is required.";
      isValid = false;
    }

    if (!formData.studentPhoto) {
      formErrors.studentPhoto = "Student photo is required.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        "https://serverapikaaruyabharathi.selfmade.express/api/admission",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSuccessMessage("Form submitted successfully!");
      setFormData({
        studentName: "",
        fatherName: "",
        motherName: "",
        dateOfBirth: "",
        gender: "",
        standard: "",
        phoneNo: "",
        address: "",
        schoolName: "",
        email: "",
        infoSource: "",
        classPlace: "",
        studentPhoto: null,
      });
    } catch (error) {
      setErrorMessage("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Nav />
      <div className="Admission-container">
        <h1>Admission Form</h1>
       
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <label htmlFor="studentName">Name of the Student*</label>
            <input
              type="text"
              id="studentName"
              name="studentName"
              placeholder="Enter student's name"
              value={formData.studentName}
              onChange={handleChange}
              required
            />
            {errors.studentName && <span className="error">{errors.studentName}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="fatherName">Father's Name*</label>
            <input
              type="text"
              id="fatherName"
              name="fatherName"
              placeholder="Enter father's name"
              value={formData.fatherName}
              onChange={handleChange}
              required
            />
            {errors.fatherName && <span className="error">{errors.fatherName}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="motherName">Mother's Name*</label>
            <input
              type="text"
              id="motherName"
              name="motherName"
              placeholder="Enter mother's name"
              value={formData.motherName}
              onChange={handleChange}
              required
            />
            {errors.motherName && <span className="error">{errors.motherName}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="dateOfBirth">Date of Birth*</label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
            {errors.dateOfBirth && <span className="error">{errors.dateOfBirth}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="gender">Gender*</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {errors.gender && <span className="error">{errors.gender}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="standard">Standard*</label>
            <input
              type="text"
              id="standard"
              name="standard"
              placeholder="Enter standard"
              value={formData.standard}
              onChange={handleChange}
              required
            />
            {errors.standard && <span className="error">{errors.standard}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="phoneNo">Phone Number*</label>
            <input
              type="text"
              id="phoneNo"
              name="phoneNo"
              placeholder="Enter phone number"
              value={formData.phoneNo}
              onChange={handleChange}
              required
            />
            {errors.phoneNo && <span className="error">{errors.phoneNo}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="address">Address*</label>
            <textarea
              id="address"
              name="address"
              placeholder="Enter address"
              value={formData.address}
              onChange={handleChange}
              required
            ></textarea>
            {errors.address && <span className="error">{errors.address}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="schoolName">Name of the School*</label>
            <input
              type="text"
              id="schoolName"
              name="schoolName"
              placeholder="Enter school name"
              value={formData.schoolName}
              onChange={handleChange}
              required
            />
            {errors.schoolName && <span className="error">{errors.schoolName}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="infoSource">How did you know about our class?*</label>
            <select
              id="infoSource"
              name="infoSource"
              value={formData.infoSource}
              onChange={handleChange}
              required
            >
              <option value="">Select an option</option>
              <option value="Instagram">Instagram</option>
              <option value="Website">Website</option>
              <option value="Relation">Relation</option>
              <option value="Other">Other</option>
            </select>
            {errors.infoSource && <span className="error">{errors.infoSource}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="classPlace">Preferred Class Location*</label>
            <select
              id="classPlace"
              name="classPlace"
              value={formData.classPlace}
              onChange={handleChange}
              required
            >
              <option value="">Select an option</option>
              <option value="Kaarunya Bharathi Arts, Rotary club, Hosur.">
                Kaarunya Bharathi Arts, Rotary club, Hosur.
              </option>
              <option value="Rama Yoga Center, SM Nagar, Hosur.">
                Rama Yoga Center, SM Nagar, Hosur.
              </option>
              <option value="Amazing kids, Play school, Mookandapalli.">
                Amazing kids, Play school, Mookandapalli.
              </option>
              <option value="Blossom Matriculation School, MM Nagar, Lal.">
                Blossom Matriculation School, MM Nagar, Lal.
              </option>
              <option value="Samadhanapuram, Dharga.">
                Samadhanapuram, Dharga.
              </option>
              <option value="Online class">Online class</option>
            </select>
            {errors.classPlace && <span className="error">{errors.classPlace}</span>}
          </div>

          <div className="input-field">
            <label htmlFor="studentPhoto">Upload Student Photo*</label>
            <input
              type="file"
              id="studentPhoto"
              name="studentPhoto"
              onChange={handleFileChange}
              required
            />
            {errors.studentPhoto && <span className="error">{errors.studentPhoto}</span>}
          </div>

          <div className="form-actions">
            <button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>

          {successMessage && <div className="success">{successMessage}</div>}
          {errorMessage && <div className="error">{errorMessage}</div>}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default AdmissionForm;
