import React, { useState } from "react";
import "../css/Farmer_Game.css";
// icon imports for navigation
import right_arrow from "../assets/icons/left_arrow.png";
import left_arrow from "../assets/icons/right_arrow.png";
// gallery images

import Original_Img_1 from '../assets/gallery_Img/original_Img/1.jpg';
import Original_Img_2 from '../assets/gallery_Img/original_Img/2.jpg';
import Original_Img_3 from '../assets/gallery_Img/original_Img/3.jpg';
import Original_Img_4 from '../assets/gallery_Img/original_Img/4.jpg';
import Original_Img_5 from '../assets/gallery_Img/original_Img/5.jpg';
import Original_Img_6 from '../assets/gallery_Img/original_Img/6.jpg';
import Original_Img_7 from '../assets/gallery_Img/original_Img/7.jpg';
import Original_Img_8 from '../assets/gallery_Img/original_Img/8.jpg';
import Original_Img_9 from '../assets/gallery_Img/original_Img/9.jpg';
import Original_Img_10 from '../assets/gallery_Img/original_Img/10.jpg';
import Original_Img_11 from '../assets/gallery_Img/original_Img/11.jpg';
import Original_Img_12 from '../assets/gallery_Img/original_Img/12.jpg';
import Original_Img_13 from '../assets/gallery_Img/original_Img/13.jpg';
import Original_Img_14 from '../assets/gallery_Img/original_Img/14.jpg';
import Original_Img_15 from '../assets/gallery_Img/original_Img/15.jpg';
import Original_Img_16 from '../assets/gallery_Img/original_Img/16.jpg';
import Original_Img_17 from '../assets/gallery_Img/original_Img/17.jpg';
import Original_Img_18 from '../assets/gallery_Img/original_Img/18.jpg';
import Original_Img_19 from '../assets/gallery_Img/original_Img/19.jpg';
import Original_Img_20 from '../assets/gallery_Img/original_Img/20.jpg';
import Original_Img_21 from '../assets/gallery_Img/original_Img/21.jpg';
import Original_Img_22 from '../assets/gallery_Img/original_Img/22.jpg';
import Original_Img_23 from '../assets/gallery_Img/original_Img/23.jpg';
import Original_Img_24 from '../assets/gallery_Img/original_Img/24.jpg';
import Original_Img_25 from '../assets/gallery_Img/original_Img/25.jpg';
import Original_Img_26 from '../assets/gallery_Img/original_Img/26.jpg';
import Original_Img_27 from '../assets/gallery_Img/original_Img/27.jpg';
import Original_Img_28 from '../assets/gallery_Img/original_Img/28.jpg';
import Original_Img_29 from '../assets/gallery_Img/original_Img/29.jpg';
import Original_Img_30 from '../assets/gallery_Img/original_Img/30.jpg';
import Original_Img_31 from '../assets/gallery_Img/original_Img/31.jpg';
import Original_Img_32 from '../assets/gallery_Img/original_Img/32.jpg';
import Original_Img_33 from '../assets/gallery_Img/original_Img/33.jpg';
import Original_Img_34 from '../assets/gallery_Img/original_Img/34.jpg';
import Original_Img_35 from '../assets/gallery_Img/original_Img/35.jpg';
import Original_Img_36 from '../assets/gallery_Img/original_Img/36.jpg';
import Original_Img_37 from '../assets/gallery_Img/original_Img/37.jpg';
import Original_Img_38 from '../assets/gallery_Img/original_Img/38.jpg';
import Original_Img_39 from '../assets/gallery_Img/original_Img/39.jpg';


//Thum_Imgs
import Thum_Img_1 from '../assets/gallery_Img/com_Img/1.jpg';
import Thum_Img_2 from '../assets/gallery_Img/com_Img/2.jpg';
import Thum_Img_3 from '../assets/gallery_Img/com_Img/3.jpg';
import Thum_Img_4 from '../assets/gallery_Img/com_Img/4.JPG';
import Thum_Img_5 from '../assets/gallery_Img/com_Img/5.JPG';
import Thum_Img_6 from '../assets/gallery_Img/com_Img/6.JPG';
import Thum_Img_7 from '../assets/gallery_Img/com_Img/7.JPG';
import Thum_Img_8 from '../assets/gallery_Img/com_Img/8.JPG';
import Thum_Img_9 from '../assets/gallery_Img/com_Img/9.JPG';
import Thum_Img_10 from '../assets/gallery_Img/com_Img/10.JPG';
import Thum_Img_11 from '../assets/gallery_Img/com_Img/11.JPG';
import Thum_Img_12 from '../assets/gallery_Img/com_Img/12.JPG';
import Thum_Img_13 from '../assets/gallery_Img/com_Img/13.JPG';
import Thum_Img_14 from '../assets/gallery_Img/com_Img/14.JPG';
import Thum_Img_15 from '../assets/gallery_Img/com_Img/15.JPG';
import Thum_Img_16 from '../assets/gallery_Img/com_Img/16.JPG';
import Thum_Img_17 from '../assets/gallery_Img/com_Img/17.JPG';
import Thum_Img_18 from '../assets/gallery_Img/com_Img/18.JPG';
import Thum_Img_19 from '../assets/gallery_Img/com_Img/19.JPG';
import Thum_Img_20 from '../assets/gallery_Img/com_Img/20.JPG';
import Thum_Img_21 from '../assets/gallery_Img/com_Img/21.JPG';
import Thum_Img_22 from '../assets/gallery_Img/com_Img/22.JPG';
import Thum_Img_23 from '../assets/gallery_Img/com_Img/23.JPG';
import Thum_Img_24 from '../assets/gallery_Img/com_Img/24.JPG';
import Thum_Img_25 from '../assets/gallery_Img/com_Img/25.JPG';
import Thum_Img_26 from '../assets/gallery_Img/com_Img/26.JPG';
import Thum_Img_27 from '../assets/gallery_Img/com_Img/27.JPG';
import Thum_Img_28 from '../assets/gallery_Img/com_Img/28.JPG';
import Thum_Img_29 from '../assets/gallery_Img/com_Img/29.JPG';
import Thum_Img_30 from '../assets/gallery_Img/com_Img/30.JPG';
import Thum_Img_31 from '../assets/gallery_Img/com_Img/31.JPG';
import Thum_Img_32 from '../assets/gallery_Img/com_Img/32.JPG';
import Thum_Img_33 from '../assets/gallery_Img/com_Img/33.JPG';
import Thum_Img_34 from '../assets/gallery_Img/com_Img/34.JPG';
import Thum_Img_35 from '../assets/gallery_Img/com_Img/35.JPG';
import Thum_Img_36 from '../assets/gallery_Img/com_Img/36.JPG';
import Thum_Img_37 from '../assets/gallery_Img/com_Img/37.jpg';
import Thum_Img_38 from '../assets/gallery_Img/com_Img/38.jpg';
import Thum_Img_39 from '../assets/gallery_Img/com_Img/39.jpg';


const Gallery_view = () => {
  const [previewUrl, setPreviewUrl] = useState(""); // State to store selected image URL
  const [isOpenPreViewImg, setIsOpenPreViewImg] = useState(false); // State to control preview modal visibility
  const galleryImg = [
    { ImgSrc: Original_Img_1, Alt: "Drawing class artwork near Hosur" },
    { ImgSrc: Original_Img_2, Alt: "Students practicing sketching at a drawing class near Hosur" },
    { ImgSrc: Original_Img_3, Alt: "Creative pencil sketches from a drawing class near Hosur" },
    { ImgSrc: Original_Img_4, Alt: "Art exhibition featuring work from a Hosur-based drawing class" },
    { ImgSrc: Original_Img_5, Alt: "Children learning to draw at a drawing class near Hosur" },
    { ImgSrc: Original_Img_6, Alt: "Advanced painting techniques taught in a Hosur drawing class" },
    { ImgSrc: Original_Img_7, Alt: "Portrait sketching session at a drawing class near Hosur" },
    { ImgSrc: Original_Img_8, Alt: "Colorful paintings created in a Hosur drawing class" },
    { ImgSrc: Original_Img_9, Alt: "Step-by-step drawing lessons near Hosur" },
    { ImgSrc: Original_Img_10, Alt: "Group drawing activities at a drawing class near Hosur" },
    { ImgSrc: Original_Img_11, Alt: "Beginner-friendly art classes near Hosur" },
    { ImgSrc: Original_Img_12, Alt: "Realistic animal drawings from a Hosur drawing class" },
    { ImgSrc: Original_Img_13, Alt: "Landscape painting techniques taught near Hosur" },
    { ImgSrc: Original_Img_14, Alt: "Creative kids' drawing classes near Hosur" },
    { ImgSrc: Original_Img_15, Alt: "Hands-on art workshops near Hosur" },
    { ImgSrc: Original_Img_16, Alt: "Professional art classes near Hosur for adults" },
    { ImgSrc: Original_Img_17, Alt: "Drawing skills improvement at a Hosur art class" },
    { ImgSrc: Original_Img_18, Alt: "Unique art styles explored in a Hosur drawing class" },
    { ImgSrc: Original_Img_19, Alt: "Drawing and painting competition at a class near Hosur" },
    { ImgSrc: Original_Img_20, Alt: "Art mentoring sessions in Hosur" },
    { ImgSrc: Original_Img_21, Alt: "Learning shading techniques at a Hosur drawing class" },
    { ImgSrc: Original_Img_22, Alt: "Group photo from a drawing class near Hosur" },
    { ImgSrc: Original_Img_23, Alt: "Student artwork from a Hosur drawing class" },
    { ImgSrc: Original_Img_24, Alt: "Art supplies and setup at a drawing class near Hosur" },
    { ImgSrc: Original_Img_25, Alt: "Holiday art workshops near Hosur" },
    { ImgSrc: Original_Img_26, Alt: "Teacher guiding students in a Hosur drawing class" },
    { ImgSrc: Original_Img_27, Alt: "Beginner art projects in a drawing class near Hosur" },
    { ImgSrc: Original_Img_28, Alt: "Student-focused drawing sessions near Hosur" },
    { ImgSrc: Original_Img_29, Alt: "Innovative art techniques taught near Hosur" },
    { ImgSrc: Original_Img_30, Alt: "Student achievements in a Hosur drawing class" },
    { ImgSrc: Original_Img_31, Alt: "Hands-on practice in a drawing class near Hosur" },
    { ImgSrc: Original_Img_32, Alt: "Detailed sketches from a Hosur art class" },
    { ImgSrc: Original_Img_33, Alt: "Live drawing sessions at a class near Hosur" },
    { ImgSrc: Original_Img_34, Alt: "Community art projects near Hosur" },
    { ImgSrc: Original_Img_35, Alt: "Art learning environment near Hosur" },
    { ImgSrc: Original_Img_36, Alt: "Creative exploration in a drawing class near Hosur" },
    { ImgSrc: Original_Img_37, Alt: "Friendly art learning atmosphere near Hosur" },
    { ImgSrc: Original_Img_38, Alt: "Skill enhancement in a Hosur drawing class" },
    { ImgSrc: Original_Img_39, Alt: "Vibrant art showcase near Hosur" }
  ];
  

  const thum_Img = [
    { ImgSrc: Thum_Img_1, Alt: "Drawing classes near Hosur for kids and adults" },
    { ImgSrc: Thum_Img_2, Alt: "Art workshops in Hosur with professional artists" },
    { ImgSrc: Thum_Img_3, Alt: "Best drawing schools in Hosur for beginners" },
    { ImgSrc: Thum_Img_4, Alt: "Learn sketching and painting near Hosur" },
    { ImgSrc: Thum_Img_5, Alt: "Affordable art classes in Hosur for students" },
    { ImgSrc: Thum_Img_6, Alt: "Hosur drawing courses with weekend batches" },
    { ImgSrc: Thum_Img_7, Alt: "Top-rated art tutors near Hosur" },
    { ImgSrc: Thum_Img_8, Alt: "Creative drawing classes in Hosur for all ages" },
    { ImgSrc: Thum_Img_9, Alt: "Enroll in professional art classes near Hosur" },
    { ImgSrc: Thum_Img_10, Alt: "Weekend art programs for kids in Hosur" },
    { ImgSrc: Thum_Img_11, Alt: "Art school in Hosur with expert instructors" },
    { ImgSrc: Thum_Img_12, Alt: "Hosur's best sketching and painting courses" },
    { ImgSrc: Thum_Img_13, Alt: "Creative art training for kids in Hosur" },
    { ImgSrc: Thum_Img_14, Alt: "Fun and engaging art classes near Hosur" },
    { ImgSrc: Thum_Img_15, Alt: "Drawing and painting courses for adults in Hosur" },
    { ImgSrc: Thum_Img_16, Alt: "Hosur art workshops with hands-on training" },
    { ImgSrc: Thum_Img_17, Alt: "Fine art classes in Hosur for aspiring artists" },
    { ImgSrc: Thum_Img_18, Alt: "Comprehensive drawing lessons in Hosur" },
    { ImgSrc: Thum_Img_19, Alt: "Art programs for kids and teens near Hosur" },
    { ImgSrc: Thum_Img_20, Alt: "Learn pencil shading and coloring in Hosur" },
    { ImgSrc: Thum_Img_21, Alt: "Affordable sketching classes near Hosur" },
    { ImgSrc: Thum_Img_22, Alt: "Advanced painting courses in Hosur" },
    { ImgSrc: Thum_Img_23, Alt: "Art classes in Hosur for hobbyists and professionals" },
    { ImgSrc: Thum_Img_24, Alt: "Weekend drawing classes near Hosur" },
    { ImgSrc: Thum_Img_25, Alt: "Customized art lessons for kids in Hosur" },
    { ImgSrc: Thum_Img_26, Alt: "Hosur art courses with practical learning" },
    { ImgSrc: Thum_Img_27, Alt: "Skill-based art training near Hosur" },
    { ImgSrc: Thum_Img_28, Alt: "Top drawing tutors in Hosur" },
    { ImgSrc: Thum_Img_29, Alt: "Learn creative art techniques near Hosur" },
    { ImgSrc: Thum_Img_30, Alt: "Personalized art classes for children in Hosur" },
    { ImgSrc: Thum_Img_31, Alt: "Hosur's art academy with expert guidance" },
    { ImgSrc: Thum_Img_32, Alt: "Sketching classes in Hosur with certification" },
    { ImgSrc: Thum_Img_33, Alt: "Professional drawing training for beginners in Hosur" },
    { ImgSrc: Thum_Img_34, Alt: "Weekend art camps for kids in Hosur" },
    { ImgSrc: Thum_Img_35, Alt: "Painting classes in Hosur with flexible timings" },
    { ImgSrc: Thum_Img_36, Alt: "Learn advanced sketching near Hosur" },
    { ImgSrc: Thum_Img_37, Alt: "Creative art workshops for families in Hosur" },
    { ImgSrc: Thum_Img_38, Alt: "Best fine art courses in Hosur for young artists" },
    { ImgSrc: Thum_Img_39, Alt: "Hosur's drawing and painting academy for enthusiasts" },
  ];
  

  // Handle clicking an image in the gallery to open the preview
  const handleGallery = (index) => {
    setPreviewUrl(galleryImg[index].ImgSrc); // Set the preview image URL
    setIsOpenPreViewImg(true); // Open the preview modal
  };

  // Function to handle left and right navigation with animation
  const handleNavigation = (direction) => {
    const currentIndex = galleryImg.findIndex((item) => item.ImgSrc === previewUrl);
    let newIndex = currentIndex;

    // Add animation class before updating the image
    const previewImage = document.getElementById('handlePreviewAnimation');
    if (direction === "left") {
      previewImage.classList.add('left-side-move');
      newIndex = currentIndex === 0 ? galleryImg.length - 1 : currentIndex - 1;
    } else if (direction === "right") {
      previewImage.classList.add('right-side-move');
      newIndex = currentIndex === galleryImg.length - 1 ? 0 : currentIndex + 1;
    }

    // Wait for the animation to finish before removing the class and updating the image
    setTimeout(() => {
      previewImage.classList.remove('left-side-move', 'right-side-move'); // Remove animation class
      setPreviewUrl(galleryImg[newIndex].ImgSrc); // Update the preview URL to the new image
    }, 400); // Adjust this timeout to match the length of your animation in CSS (300ms is a common duration)
  };

  return (
    <>
      <div className="Farmer-Game-container">
        <div className="Farmer-Game">
          {/* Gallery of images */}
          <div className="gallery_Img">
            {thum_Img.map((item, index) => (
              <img
                key={index}
                src={item.ImgSrc}
                alt={item.Atl || "Gallery Image"}
                onClick={() => handleGallery(index)} // Open preview on click
              />
            ))}
          </div>
        </div>
      </div>

      {/* Image Preview Modal */}
      <input
        type="checkbox"
        id="handlePreviewImg"
        checked={isOpenPreViewImg}
        onChange={() => setIsOpenPreViewImg(!isOpenPreViewImg)} // Toggle the preview visibility
      />
      <div className="view-img-container" >
       <div className="overflow" onClick={()=>setIsOpenPreViewImg(false)}>
        
       </div>
        <div className="btn">
          <div className="left" onClick={() => handleNavigation("left")}>
            <img src={left_arrow} alt="Left Arrow" />
          </div>
          <div className="right" onClick={() => handleNavigation("right")}>
            <img src={right_arrow} alt="Right Arrow" />
          </div>
        </div>
        <div className="view-img">
          <div className="img">
            <div
              className="close-img"
              onClick={() => setIsOpenPreViewImg(false)} // Close the preview
            >
              X
            </div>
            <img src={previewUrl} alt="Preview" id="handlePreviewAnimation" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery_view;
