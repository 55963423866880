import React from "react";
import "../../css/Children_Art_Course.css";
import Nav from "../../componet/Nav";
import Footer from "../../componet/Footer";

const Tanjore_Painting = () => {
  return (
    <>
      <Nav />
      <div className="children-art-course-container">
        <div className="children-art-course">
          <div className="course_Img">
            <h1>*Diploma Tanjore Paintings ~</h1>
          </div>
          <div className="content">
            <p>
              A Diploma in Tanjore Painting is a specialized academic program
              that focuses on the traditional Indian art form of Tanjore
              painting, which originates from the town of Tanjore in Tamil Nadu,
              India. This diploma course teaches students the techniques,
              history, and cultural significance of Tanjore painting, an
              intricate art form known for its rich colors, surface
              ornamentation, and the use of gold foil.
            </p>
            <h1>
              Learning Tanjore painting offers numerous benefits, both artistic
              and personal. Here are some key advantages:
            </h1>
            <ul>
              <li>
                <strong>1. Cultural Appreciation:</strong> Tanjore painting is a
                rich part of India’s artistic heritage. Learning this art form
                helps you connect with and appreciate traditional Indian culture
                and history.
              </li>
              <li>
                <strong>2. Enhancing Creativity:</strong> The process encourages
                creativity, as you explore new techniques, colors, and textures
                while creating unique artwork.
              </li>

              <li>
                <strong>3. Skill Development:</strong> Mastering intricate
                techniques like raised texture work, gold leaf application, and
                fine detailing helps improve fine motor skills, patience, and
                precision.
              </li>

              <li>
                <strong>4. Artistic Expression:</strong> Tanjore painting allows
                you to express emotions, spirituality, and stories through art,
                especially with its focus on divine themes and intricate
                details.
              </li>

              <li>
                <strong>5. Therapeutic Benefits:</strong> The meditative and
                focused nature of painting can be therapeutic, reducing stress
                and improving mental well-being.
              </li>
              <li>
                <strong>6. Career Opportunities:</strong>Learning Tanjore
                painting can open doors for a career in art, whether as a
                professional artist, art teacher, or in the art preservation
                field.
              </li>
              <li>
                <strong>7. Preserving Tradition:</strong> By learning Tanjore
                painting, you contribute to the preservation and promotion of
                this traditional art form, keeping it alive for future
                generations.
              </li>
              <li>
                <strong>8.Hands-on Experience:</strong> Emphasizes practical
                work and real-world application, often involving workshops,
                studio time, and exhibitions.
              </li>
              <li>
                <strong>8. Enhancing Aesthetic Sense:</strong> The use of
                colors, textures, and embellishments in Tanjore painting
                sharpens your aesthetic sense and ability to appreciate
                intricate artwork.
              </li>

              <li>
                <strong>9. Building Patience and Focus:</strong> The detailed
                and time-consuming process of Tanjore painting helps develop
                patience, focus, and perseverance.
              </li>

              <li>
                <strong>9. Building Patience and Focus:</strong> The detailed
                and time-consuming process of Tanjore painting helps develop
                patience, focus, and perseverance.
              </li>

              <li>
                In summary, learning Tanjore painting is not only a way to
                enhance your artistic skills but also an opportunity to engage
                with a culturally rich tradition, express creativity, and
                experience personal growth.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tanjore_Painting;
